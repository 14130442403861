import { disconnect } from 'process';
import React, { useContext, useState } from 'react'
import { text } from 'stream/consumers';
import Button from './Button';
import appContext from 'utils/app-context';
import tg from 'utils/tg';

type Props = {}

const SetLanguageModal = (props: Props) => {
  const {locale,langs,setLanguage} = useContext(appContext)
  const [loading, setloading] = useState<string | false>(false)
  if(!locale) return null
  const text = locale?.general

  const handleClick = async (code:string) => {
    console.log("🚀 ~ handleClick ~ locale.code:", locale.code)
    console.log("🚀 ~ handleClick ~ code:", code)
    if(code.toLowerCase() === locale.code) return
    setloading(code)
    const res = await setLanguage(code)
    if(res) tg.HapticFeedback.notificationOccurred('success')
    setloading(false)
  }

  return (
    <>
    <h2 dangerouslySetInnerHTML={{__html:text?.selectLang}} style={{ textAlign: "center", fontWeight: 500 }}/>
    <ul className='select-lang-list'>
      {langs?.map(({displayName,icon,code}) => (
        <li key={code}>
          <Button loading={loading === code} onClick={() => handleClick(code)} sandBtn={code.toLowerCase() === locale.code}>
          {displayName} {icon}
          </Button>
        </li>
      ))}
    </ul>
  </>
  )
}

export default SetLanguageModal