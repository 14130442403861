import React, { Dispatch, SetStateAction, useContext } from "react";
import Button from "UI/Button";
import EarnSections, { EarnSectionType } from "../earn-sections";
import appContext from "utils/app-context";

type Props = {
  setSection:(section:EarnSectionType) => void;
  current: EarnSectionType;
};

const EarnNavigation = ({ setSection, current }: Props) => {
  const {locale} = useContext(appContext)
  return (
    <ul className="earn-nav">
      {EarnSections.map((sec) => (
            <li key={sec.title} className={current?.title === sec.title ? `active` : ""}>
              <Button onClick={() => setSection(sec)}>{locale?.earnPage.sectionTitles[sec.title]}</Button>
            </li>
          ))}
    </ul>
  );
};

export default EarnNavigation;
