import doneTask from 'api/done-task'
import { motion } from 'framer-motion'
import { title } from 'process'
import React, { useContext, useState } from 'react'
import Task from 'types/task'
import Button from 'UI/Button'
import Icon from 'UI/Icon'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'
import tg from 'utils/tg'
import coin from 'assets/images/coin.png'

type Props = {
    t:Task
    updateTasks:() => void
}

const TaskCard = ({t:{id,reward,title,url,status,icon},updateTasks}: Props) => {
  const [pending, setpending] = useState(false)
  const {setUser,user,locale} = useContext(appContext)

  const HandleTask = async () => {
    setpending(false)
      const result = await doneTask(id)
      const err = () => tg.showAlert('A mistake arose')
      switch (result?.status) {
          case 2:
            // if (!url) toast(text.taskNotDone,{autoClose:1000,hideProgressBar:true,type:'info'})
          break;
      }
      if(result?.balance && user) setUser({...user,balance:result?.balance})
      if(!result) err()
      updateTasks()
  } 

  const claimTask = (open:boolean) => {
    if(status === 1) return
    setpending(true)
    if (url && open) {
      try {
        tg.openTelegramLink(url)
      } catch (error) {
        tg.openLink(url)
      }
    }
    

    setTimeout(HandleTask,3000)
  }

  return (
    <motion.li layout layoutId={id} className="boosters-list-item task-card" >
            <div className="energy-booster-img task-img">
            <object type="image/svg+xml" data={`img/tasks-icons/${icon}.svg`}>
              <img src={`img/tasks-icons/${icon}.svg`} alt="" />
            </object>
            </div>
            <div className="boosters-list-item-desc">
              <h4>{title}</h4>
              <div className='per-tap'>
              <img src={coin} className="coin-img"/>
              <span>+{formatNumber(reward)}t</span>
              </div>
              <div className="boosters-list-item-btn-container">
              <Button className={`boosters-list-item-btn`} sandBtn={status !== 1} onClick={() => claimTask(true)}>
                <p>{status === 1 ? locale?.tasksPage.done : locale?.tasksPage.go}</p>
              </Button>
              {status === 2 && <Button className={`boosters-list-item-btn`} onClick={() => claimTask(false)}>
              {pending ? <Icon icon='loading'/> : <p>{locale?.tasksPage.check}</p>}
              </Button>}
              {/* <div className="boosters-list-item-cost">
              <img src={coin} className="coin-img"/>
                <span>+{formatNumber(reward)}t</span>
              </div> */}
              </div>
            </div>
          </motion.li>
  )
}

export default TaskCard