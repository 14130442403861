import React, { useContext, useEffect, useRef, useState } from "react";
import EarnSection from "../components/EarnSection";
import Icon from "UI/Icon";
import UserBalance from "UI/UserBalance";
import Button from "UI/Button";
import appContext from "utils/app-context";
import DailyRewardsInfo from "types/daily-rewards-info";
import getDailyRewards from "api/get-daily-rewards";
import { motion } from "framer-motion";
import { containerVariants, itemVariants } from "utils/variants-animations";
import claimDailyReward from "api/claim-daily-reward";
import tg from "utils/tg";
import formatNumber from "utils/format-number";
import TimerText from "UI/TimerText";
import coin from 'assets/images/coin.png'

type Props = {};

const EarnDaily = (props: Props) => {
  const [dailyRewards, setDailyRewards] = useState<DailyRewardsInfo| null>(null)
  const { setModal,setUser,user,locale } = useContext(appContext);
  const [loading, setloading] = useState(false)
  const currentRef = useRef<HTMLLIElement>(null);
  const updateDailyRewards = async () => {
    setloading(true)
    const result = await getDailyRewards()
    if(result) setDailyRewards(result)
    setloading(false)
  }

  const handleClaimDailyReward = async () => {
    if(!dailyRewards?.available) {
      tg.HapticFeedback.notificationOccurred('error')
      return 
    }
    setloading(true)
    const result = await claimDailyReward()
    if(result) {
      if(user) setUser({...user,balance:result.balance})
        setModal({ close: false, content: <GetDailyModal token={dailyRewards.stages[dailyRewards.current_stage]}/> })
    }
    updateDailyRewards()
  }

  useEffect(() => {
    updateDailyRewards()
  }, [])
  useEffect(() => {
      if(!dailyRewards || !currentRef.current) return
      // currentRef.current.scrollIntoView({ behavior: "smooth" });
  }, [dailyRewards])
  if(!locale) return null

  const text = locale.earnPage.daily
  const mainText = locale.general
  return (
    <div>
      <UserBalance />
      <h1>{text.desc}</h1>
      <motion.ul
        // variants={containerVariants}
         initial="hidden"
          animate="visible"
        className="daily-reward-list">
        {dailyRewards ? dailyRewards.stages.map((stage, i) => (
          <motion.li
            ref={i === dailyRewards.current_stage ? currentRef : null}
            // variants={itemVariants}
            className={`daily-reward-item ${i === dailyRewards.current_stage ? "current" : ""}`}
            key={i + stage}
          >
            <div className="daily-reward-item-wrapper">
              <span>{text.day} {i + 1}</span>
              <img src={coin}/>
              <span className="reward-value">+{formatNumber(stage)}</span>
            </div>
          </motion.li>
        )) : <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:20}}><Icon icon="loading"/></div>}
      </motion.ul>
      <Button
        errorIf={!dailyRewards?.available}
        sandBtn={dailyRewards?.available}
        className="daily-reward-gain"
        loading={loading}
        onClick={handleClaimDailyReward}
      >
        {
          dailyRewards?.available
            ? text.gain
            : <p>{mainText.availableVia} {dailyRewards && <TimerText time={dailyRewards.seconds} onEnd={updateDailyRewards}/>}</p>
        }
      </Button>
    </div>
  );
};

const GetDailyModal = ({token}:{token:number}) => {
  const { setModal,locale } = useContext(appContext);
  if(!locale) return null
  const text = locale.earnPage.daily
  const mainText = locale.general
  return (
    <>
      <h2 style={{textAlign:'center',fontWeight:500}} dangerouslySetInnerHTML={{__html:text.dailyModalTitle_html}}/>
      <p style={{textAlign:'center',margin:'5px 0 20px'}}>
        <span className="sand-text">+{formatNumber(token)} {mainText.tkn_many}</span> {text.onYourBal}
      </p>
      <Button
          sandBtn
          className="daily-reward-gain"
          onClick={() => setModal(null)}
        >
          {mainText.continue}
        </Button>
    </>
  );
}

export default EarnDaily;
