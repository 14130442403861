import React, { useContext, useEffect, useRef, useState } from "react";
import Icon from "UI/Icon";
import PageWrapper from "UI/PageWrapper";
import './Entry.css'
import Button from "UI/Button";
import { motion, Target } from "framer-motion";
import { useNavigate } from "react-router-dom";
import appContext from "utils/app-context";
import logo from 'assets/images/logo.png'
import SetLanguageModal from "UI/SetLanguageModal";
type Props = {};

const Entry = (props: Props) => {
  const description = useRef<HTMLDivElement>(null)
  const navigation = useNavigate()
  const {locale,langs,setModal} = useContext(appContext)

  const measureDesc = () =>{
    if(!description.current) return
    const {top,height} = description.current.getBoundingClientRect()
    const WinHeight = window.innerHeight
    // setinitial({y:top - WinHeight - 100})
    description.current.style.transform = `translateY(-${top - WinHeight - height}px)`
  } 

  useEffect(() => {
    measureDesc()
  }, [])
  if(!locale) return null

  const langIcon = langs?.find(({code}) => code.toLowerCase() === locale?.code)?.icon
  const text = locale?.aboutPage
  const mainText = locale?.general
  return (
    <PageWrapper className="entry-page">
      <img src={logo} alt="" className="entry-page-logo"/>
      <ul className="entry-socials">
        <li>
          <a target="_blank" href="https://t.me/pearl_hab">
            <Icon icon="socialsTg" />
          </a>
        </li>
        {/* <li>
          <a href="#">
            <Icon icon="socialsWhatsApp" />
          </a>
        </li> */}
        <li>
          <a target="_blank" href="https://www.instagram.com/pearlhub.official/profilecard/?igsh=MXZhYjFpYzJ1MDgzYg==">
            <Icon icon="socialsInst" />
          </a>
        </li>
        {/* <li>
          <a href="#">
            <Icon icon="socialsX" />
          </a>
        </li> */}
      </ul>
      <p dangerouslySetInnerHTML={{__html:text.pageDesc_html}}/>
        <div className="entry-buttons">
        <Button className="about-token entry-select-lang" onClick={() => setModal({content:<SetLanguageModal/>,close:true})}>
         <span>
          <Icon icon="lang"/>
          {mainText?.yourLang}
         </span>
         <span>
          {locale?.displayName} <b>{langIcon}</b>
         </span>
        </Button>
        <Button className="play-btn" onClick={() => navigation('/tap')}>{text.play}</Button>
        </div>
      <div className="entry-desc-container">
        <img className="entry-img" src="./img/entry-img.png?v=1" alt="" />
        <motion.div
          // initial={initial}
          // drag={"y"}
          // ref={description}
          // dragElastic={1}
          // dragTransition={{ bounceStiffness: 100, bounceDamping: 1 }}
          // dragConstraints={constraintsRef}
          className="entry-description-block"
        >
        <div className="entry-description-content" dangerouslySetInnerHTML={{__html:text.aboutToken_html}}>
          {/* <h2>Earn money</h2>
            <p>Complete simple tasks and get pearl hub tokens</p>
            <hr />
            <h2>Pearl hub token</h2>
            <p>The only token associated with such sites as: OnlinyFans, PornHub and others from the porn industry <br /> <br />
            The prospects of the token: <br />
            Creating a single ecosystem in the porn industry 
            Centralization of the payment system 
            Convenience due to a single information field
            The opportunity to earn money through arbitrage marketing</p> */}
        </div>
          <img src="./img/entry-desc-img.png" alt="" />
        </motion.div>
      </div>
     
    </PageWrapper>
  );
};

export default Entry;
