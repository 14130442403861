import { useContext, useEffect } from 'react'
import './Tap.css'
import PageWrapper from 'UI/PageWrapper'
import UserBalance from 'UI/UserBalance'
import Icon from 'UI/Icon'
import Button from 'UI/Button'
import energyImg from 'assets/images/energy-icon.png'
import Oyster from './components/Oyster'
import formatNumber from 'utils/format-number'
import appContext from 'utils/app-context'
import { Link } from 'react-router-dom'
import SetLanguageModal from 'UI/SetLanguageModal'
type Props = {}

const Tap = (props: Props) => {
  const {user,locale,setModal,langs} = useContext(appContext)
  useEffect(() => {
    const isFirstLaunch = localStorage.getItem('isFirstLaunch')
    if(!isFirstLaunch) localStorage.setItem('isFirstLaunch','true')
  }, [])
  if(!user || !locale) return null
  const text = locale.tapPage
  const mainText = locale.general
  const langIcon = langs?.find(({code}) => code.toLowerCase() === locale?.code)?.icon

  return (
    <PageWrapper className='tap-page'>
      <UserBalance/>
      <div className="income">
        <div>
        <div className='income-item'>
          <Icon icon='minCoin'/>
          <p>{formatNumber(user.profit_hour)} <span>{mainText.profitHour}</span></p>
        </div>
        <div className='income-item'>
          <Icon icon='tapIcon'/>
          <p>{formatNumber(user.coins_per_tap)} <span>{mainText.tokenTap}</span></p>
        </div>
        </div>
        <Button className="about-token select-lang-sm" onClick={() => setModal({content:<SetLanguageModal/>,close:true})}>
        <Icon icon='lang'/>
        <b>{langIcon}</b>
      </Button>
      </div>
      <div className="about-token-container">
      <Button className="about-token" >
        <Icon icon='aboutIcon'/>
        <Link to={'/'}>{text.aboutBtn}</Link>
      </Button>
      </div>
      <Oyster/>
      <div className="energy-container">
        <div className="energy-value">
          <img src={energyImg} alt="" />
          <p><span>{formatNumber(user?.energy)}</span> / {formatNumber(user?.max_energy)}</p>
        </div>
        <div className="energy-progress-container">
          <div className="energy-progress-wrapper">
            <div className="energy-progress-line-container">
              <div className="energy-progress-line" style={{width:`${user?.energy / 10000 * user?.max_energy }%`}}/>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default Tap