import Product from "types/product";
import ProductCurrency from "types/product-currency";
import UserData from "types/user-data";

export default (p?: Product,user?:UserData | null): ProductCurrency | null => {
  if(!user || !p) return null
  switch (true) {
    case !!p.price_ton:
      return "price_ton";

    case !!p.price_friends && user.friends >= p.price_friends:
      return "price_friends";

    case !!p.price && user.balance >= p.price:
      return "price";
      
    default:
      return null;

      break;
  }
};
