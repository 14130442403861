import React, { useContext, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import UserBalance from "UI/UserBalance";
import "./Friends.css";
import gift from "assets/images/gift.png";
import Icon from "UI/Icon";
import Button from "UI/Button";
import tg from "utils/tg";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import coin from 'assets/images/coin.png'

const tg_id = tg.initDataUnsafe.user?.id;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
const shareText = process.env.REACT_APP_SHARE_TEXT || '';

const Friends = () => {
  const [copy, setcopy] = useState(false)
  const {user,locale} = useContext(appContext)
  const copyShareLink = (e:React.MouseEvent) => {
    setcopy(true)
    tg.HapticFeedback.notificationOccurred('success')
    navigator.clipboard.writeText(inviteLink)
    setTimeout(() => setcopy(false),1000)
  }
  const TelegramShare = () => {
      tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}`)
  }
  if(!locale) return null
  const text = locale.friendsPage
  const mainText = locale.general
  return (
    <>
    <PageWrapper className="friends-page">
      <UserBalance />
      <div className="friends-page-info">
        <div className={user?.friends ? 'has-friends' : ''}>
          <img className="friends-page-gift" src={gift} alt="" />
          <h1 dangerouslySetInnerHTML={{__html:text.title_html}}/>
        </div>
        <div className="invite-friend">
          <div className="invite-friend-desc">
            <h3>{text.hintTitle}</h3>
            <span>
              <img src={coin}/>
              {text.hintDesc}
            </span>
          </div>
          <div className="invite-friend-buttons-wrapper">
            <Button onClick={copyShareLink}>{!copy ? text.copy : text.copied}</Button>
            <Button onClick={TelegramShare} sandBtn>{text.share}</Button>
          </div>
        </div>
      </div>
      {!!user?.friends && 
        <div className="friends-list-container">
          <div className="boosters-list-wrapper">
        <div className="divider">
          <hr /> <h3>{text.listTitle}</h3>  <hr />
        </div>
        <ul className="boosters-list friends-list">
          {user.friends_list.map(({coins,name,photo}) => (
             <li className="boosters-list-item friends-list-item">
             <img src={photo} alt=""/>
             <div className="boosters-list-item-desc">
               <p>{name}</p>
               <div className="per-tap">
                <img src={coin} className="coin-img"/>
                <p>
                  +{formatNumber(coins)}t
                </p>
              </div>
             </div>
           </li>
          ))}
        </ul>
      </div>
        </div>
      }
      
    </PageWrapper>
    <div className="invite-friend-counter">
        <Icon icon="navigationFriends"/>
        <p>{user?.friends} {text.friendsCounter}</p>
      </div></>
  );
};

export default Friends;
