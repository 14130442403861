import { motion } from "framer-motion";
import usePurchase from "hooks/use-purchase";
import { useContext } from "react";
import Product from "types/product";
import Button from "UI/Button";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import tg from "utils/tg";
import coin from 'assets/images/coin.png'

type Props = {
  product: Product;
};

const EarnTappingBoosterCard = ({ product }: Props) => {
  const { setModal,locale } = useContext(appContext);
  const { boost_tap, image, price, price_ton, title, price_friends,in_inventory } = product;
  if(!locale) return null
  const text = locale.earnPage.tapping
  const mainText = locale.general
  return (
    <motion.li
      key={product.id}
      layout
      layoutId={String(product.id)}
      className="boosters-list-item tapping-goods-list-item"
      onClick={() => {
        if(in_inventory) return
        setModal({ content: <BoostModal good={product} />, close: true })
      }}
    >
      <img src={image} alt="" />
      <div className="boosters-list-item-desc tapping-good-desc">
        <h4>{title}</h4>
        <div className="per-tap">
          <img src={coin} className="coin-img"/>
          <p>
            +{boost_tap}{mainText.tkn_short}<span>/{mainText.tap}</span>
          </p>
        </div>
        <p className="tapping-good-cost-text" style={{opacity:Number(!in_inventory)}}>
          {text.willCostOneOf}
        </p>
        <ul className="cost-variants" style={{opacity:Number(!in_inventory)}}>
          {!!price && (
            <li>
              <img src={coin} className="coin-img"/>
              <span>{price / 1000}k</span>
            </li>
          )}
          {!!price_friends && (
            <li>
              <Icon icon="person" />
              <span>{price_friends}</span>
            </li>
          )}
          {!!price_ton && (
            <li>
              <Icon icon="ton" />
              <span>{price_ton}</span>
            </li>
          )}
        </ul>
      </div>
    </motion.li>
  );
};



type BoostModalProps = { good: Product;};
const BoostModal = ({
  good,
}: BoostModalProps) => {
  const { title, price_ton, price, price_friends ,id} = good
  const { user ,setModal,updateProducts,updateUserInfo,locale} = useContext(appContext);
  const {handleBuyProduct,loading,success,currency,setcurrency} = usePurchase(good)
  
  if (!user || !locale) return null;
  const text = locale.earnPage.tapping
  const mainText = locale.general
  const avalaibleForFriends = user?.friends >= price_friends;
  const avalaibleForCoins = user?.balance >= price;
  return (
    <>
      <h2 style={{ textAlign: "center", fontWeight: 500 }}>
        {text.tappingModalTitle} <br />
        <span className="sand-text2">«{title}»</span>
      </h2>
      <p style={{ textAlign: "center", margin: "5px 0 20px" }}>
       {text.tappingModalDesc}
      </p>
      <ul className="buy-boost-buttons">
        {!!price_ton && (
          <li>
            <Button
              className={`buy-boost-select-currency tg-currency ${
                currency === "price_ton" && "active"
              }`}
              onClick={() => setcurrency("price_ton")}
              style={{}}
            >
              <Icon icon="ton" /> {price_ton} {mainText.currencyTon}
            </Button>
          </li>
        )}
        {!!price_friends && (
          <li>
            <Button
              className={`buy-boost-select-currency fr-currency ${
                currency === "price_friends" && "active"
              }`}
              errorIf={!avalaibleForFriends}
              onClick={() => {
                if (avalaibleForFriends) setcurrency("price_friends");
                else tg.HapticFeedback.notificationOccurred("error");
              }}
            >
              <Icon icon="person" />{" "}
              {avalaibleForFriends ? "" : `${user.friends} /`} {price_friends}{" "}
              {mainText.currencyFriends}
            </Button>
          </li>
        )}
        {!!price && (
          <li>
            <Button
              className={`buy-boost-select-currency cn-currency ${
                currency === "price" && "active"
              }`}
              errorIf={!avalaibleForCoins}
              onClick={() => {
                if (avalaibleForCoins) setcurrency("price");
                else tg.HapticFeedback.notificationOccurred("error");
              }}
            >
              <img src={coin}/>{" "}
              {avalaibleForCoins ? "" : `${user.balance} /`}{" "}
              {formatNumber(price)} {mainText.tkn_many}
            </Button>
          </li>
        )}
      </ul>
      <Button sandBtn loading={loading} className="buy-boost-btn" 
        onClick={() => handleBuyProduct(() => {
          setTimeout(() => {
            setModal(null)
            updateUserInfo()
            setTimeout(updateProducts,400)
          }, 500);
        })}
        >
        {success ? mainText.success : mainText.continue}
      </Button>
      <div className="not-available-info">
        {!avalaibleForFriends && <p>{mainText.enoughtFriends}</p>}
        {!avalaibleForCoins && <p>{mainText.enoughtTokens}</p>}
      </div>
    </>
  );
};
export default EarnTappingBoosterCard;
