import getPrizes from "api/get-prizes";
import getRoulettePrize from "api/get-roulette-prize";
import { motion, useAnimation } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import Prize from "types/prize";
import Button from "UI/Button";
import Icon from "UI/Icon";
import PageWrapper from "UI/PageWrapper";
import TimerText from "UI/TimerText";
import UserBalance from "UI/UserBalance";
import appContext from "utils/app-context";
import tg from "utils/tg";
import EarmRouletteMyPrizes from "../components/EarmRouletteMyPrizes";
import formatNumber from "utils/format-number";
import EarnRouletteWinPrize from "../components/EarnRouletteWinPrize";
import shuffleArray from "utils/shuffle-array";
import { useNavigate } from "react-router-dom";
import coin from 'assets/images/coin.png'
import usePurchase from "hooks/use-purchase";

const CARD_SIZE = 140

const EarnRoulette = () => {
  const { user, locale, updateUserInfo,setModal,products } = useContext(appContext);
  console.log("🚀 ~ EarnRoulette ~ products:", products)
  const controls = useAnimation();
  const navigate = useNavigate()
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const [displayed, setDisplayed] = useState<Prize[]>([]);
  const [spinning, setspinning] = useState(false)
  const container = useRef<HTMLDivElement>(null);
  const track = useRef<HTMLUListElement>(null);
  const getSpinProduct = products?.find(({get_spin}) => Number(get_spin))
  const {handleBuyProduct,loading} = usePurchase(getSpinProduct)

    const openPrizes = () => setModal({content:<EarmRouletteMyPrizes prizes={prizes}/>})

    const updatePrizes = async () => {
        const res = await getPrizes()
        if(res) setPrizes(res);
        return res
    }

  useEffect(() => {
    updateUserInfo()
    updatePrizes().then((res) => {
      if (res) {
        const prizePlace:Prize = {
            id:Infinity,
            image:'',
            in_inventory:Infinity,
            title:'',
            is_prize_place:true
        }
        res = shuffleArray(res)
        setDisplayed([...res, ...res,prizePlace, ...res]);
      }
    });
  }, []);

  const spinRoulette = async () => {
    if(spinning || !user?.roulette) return
    setspinning(true)
    if (!container.current || !track.current || !user?.roulette) return;
    const result = await getRoulettePrize();

    if (!result || "error" in result) {
      tg.showAlert("Something went wrong");
    setspinning(false)
      return;
    }

    const prize = prizes.find((p) => p.id === result.prize_id);
    if (!prize) {
      tg.showAlert("Prize not found");
    setspinning(false)
      return;
    }


    // Помещаем выигранный приз в конец списка
    setDisplayed(prev => prev.map(p => p.is_prize_place ? prize : p));

    // Получаем половину ширины track
    const containerWidth = container.current.getBoundingClientRect().width;
    console.log();
    const distance = (prizes.length * 2) * CARD_SIZE - ((containerWidth - CARD_SIZE) / 2)
    // const trackWidth = track.current.getBoundingClientRect().width;
    // const halfWidth = trackWidth / 2;
    const duration = 3
    // Запускаем вращение рулетки
    controls.start({
      x: [0, -distance], // Вращение до половины ширины
      transition: {
        ease: "anticipate",
        duration,
      }
    });
    setTimeout(() => {
        setspinning(false)
        updateUserInfo()
        // setReceivedPrize(prize);
        setModal({
            content:<EarnRouletteWinPrize
            prize={prize} 
            />,
        })
        setTimeout(() => {
            controls.set({x:0})
        }, 500);
    }, duration * 1000 + 50);
  };


  if (!locale || !user) return null;
  const text = locale.earnPage.roulette;
  const mainText = locale.general;

  const {roulette_cond_friends,roulette_cond_sub} = user
  const isAvailable = roulette_cond_friends && roulette_cond_sub
  return (
    <div className="roulette-page">
      <ul className="earn-boosters-stats">
        <li>
        <img src={coin} />
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>{mainText.balance}</span>
          </div>
        </li>
        <li>
          <Icon icon="usdt" />
          <div>
            <p>{formatNumber(user?.usdt)}</p>
            <span>{mainText.usdt}</span>
          </div>
        </li>
      </ul>
      <h1 dangerouslySetInnerHTML={{__html:text.decs_html}}/>
      <div className="roulette-container">
        <hr />
        <div className="roulette-track-container" ref={container}>
          {displayed && (
            <motion.ul
              //   style={{ x }}
              animate={controls}
              className="roulette-track"
                ref={track}
            >
              {displayed.map(({ image, id,is_prize_place }, index) => (
                <li key={index} data-id={id}>
                  {is_prize_place
                  ? <motion.img src={`img/prizes/${image}`} layout layoutId={String(id)} />
                  : <img src={`img/prizes/${image}`} />}
                </li>
              ))}
            </motion.ul>
          )}
        </div>
        <hr />
      </div>
      <div className="button-container">
      <Button className="boosters-list-item-btn" onClick={openPrizes}>{text.myPrizes}</Button>
        {isAvailable
        ? ((user?.roulette_seconds > 0 && getSpinProduct) && <>
          <h2 style={{marginTop:10}}>{text.youCanSkip}</h2>
          <Button
          style={{width:'70%'}}
          loading={loading}
          onClick={() => handleBuyProduct(updateUserInfo,)}
          sandBtn className="boosters-list-item-btn">Skip the time for {getSpinProduct.price_ton} <Icon icon="ton"/></Button>
        </>)
        : <>
        <h2>{text.toParticipate}</h2>
        {!roulette_cond_friends && <p>{text.inviteCond}</p>}
        {!roulette_cond_sub && <p>{text.subCond}</p>}
        </>
        }
      </div>
      {isAvailable ?<Button
          errorIf={!user?.roulette}
          sandBtn={user?.roulette}
          className="daily-reward-gain spin-btn"
          onClick={spinRoulette}
          loading={spinning}
        >
          {user?.roulette ? (
            text.spin
          ) : (
            <p>
              {mainText.availableVia}{" "}
              {user && (
                <TimerText
                  time={user?.roulette_seconds}
                  onEnd={updateUserInfo}
                />
              )}
            </p>
          )}
          {!spinning && <Icon icon="navigationRoulette" />}
        </Button> : <div className="daily-reward-gain roulette-not-available-buttons">
        <Button 
        onClick={() => navigate('/tasks')}
        sandBtn={Boolean(roulette_cond_sub)} className="boosters-list-item-btn">{
          roulette_cond_sub ? text.subDone : text.sub  
        }</Button>
        <Button 
        onClick={() => navigate('/friends')}
        sandBtn={Boolean(roulette_cond_friends)}  className="boosters-list-item-btn">{
          roulette_cond_friends ? text.inviteDone : text.invite  
        }</Button>
        </div>}
    </div>
  );
};

export default EarnRoulette;
