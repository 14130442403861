import { createContext, ReactNode, useEffect, useRef, useState } from "react"
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import UserData from "types/user-data";
import AppContext from "utils/app-context";
import tg from "utils/tg";
import ModalProps from "types/modal";
import Product from "types/product";
import getProducts from "api/get-products";
import Lang from "types/lang";
import getLanguages from "api/get-languages";
import getLang from "utils/get-lang";
import getLocale from "api/get-locale";
import Locale from "types/locale";
const errors = {
    "appError":"It seems that some kind of error has occurred, you need to go back to the bot and send /start",
    "quit":"Back to the bot"
}
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(1)) || ""
export default () => {
    const [user, setUser] = useState<UserData | null>(null)
    const [products, setProducts] = useState<Product[] | null>(null)
    const [langs, setLangs] = useState<Lang[] | null>(null)
    const [locale, setLocale] = useState<Locale | null>(null)
    const [showGameRules, setshowGameRules] = useState(false)
    const [modal, setModal] = useState<ModalProps | null>(null)
    const interval = useRef<NodeJS.Timer | null>(null)

    const updateUserInfo = async () => {
        const result = await getUserData()
        if (result){
            // const correction:UserData = {
            //     ...result,
            //     roulette_cond_friends:0,
            //     roulette_cond_sub:0,
            // }
            // setUser(correction)
            setUser(result)
        }
        if(result?.error) {
            tg.showPopup({message:errors.appError,buttons:[{type:'close',text:errors.quit}]},() => {
                tg.close()
                tg.openTelegramLink(inviteLink)
            })
        }
    }

    const updateProducts = async () => {
        const result = await getProducts()
        if (result) setProducts(result)
        return result
    }

    const updatelangs = async () => {
        const result = await getLanguages()
        if (!result) return null

        setLangs(result)
        const l = getLang(result.map(({code}) => code))
        const res = await getLocale(l)
        if(res) setLocale(res)

        return result
    }

    const setLanguage = async (code:Lang['code']) => {
       try {
        if(!langs) return
        const codes = langs.map(({code}) => code)
        if(!codes.includes(code)) return
        const res = await getLocale(code)
        if(!res) return false

        setLocale(res)
        localStorage.setItem('lang',code)

        return true
       } catch (error) {
        return false
       }
    }

    const AppContextValue:AppContextType = {
        user,
        langs,
        updateUserInfo,
        setLanguage,
        setUser,
        locale,
        showGameRules,
        setshowGameRules,
        modal,setModal,products,updateProducts
    }


    useEffect(() => {
        updateUserInfo()
        updateProducts()
        updatelangs()
    }, [])
    // useEffect(() => {
    //     if(interval.current) clearInterval(interval.current)
    //     if (!user) return;

    //     // const { lang, max_energy, energy_speed } = user;
    //     const { max_energy, energy_speed } = user;
    
    //     // Обновляем язык, если он изменился
    //     // if (lang !== language) setLanguage(lang || 'en');
        
    //     interval.current = setInterval(() => {
    //         setUser((prevUser) => {
    //             if (!prevUser) return prevUser;
    
    //             const { energy, max_energy } = prevUser;
    
    //             // Если энергия меньше максимальной, прибавляем
    //             if (energy < max_energy) {
    //                 let nextValue = energy + energy_speed;
    //                 nextValue = nextValue > max_energy ? max_energy : nextValue;
    //                 return { ...prevUser, energy: nextValue };
    //             }
    
    //             return prevUser;
    //         });
    //     }, 1000);
    
    //     return () => {
    //         if(interval.current) clearInterval(interval.current)
    //     };
    // }, [user])
    return {AppContext,AppContextValue}
}