import { useContext } from "react";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import EarnTappingBoosterCard from "../components/EarnTappingBoosterCard";
import Product from "types/product";
import TonConnectButton from "../../../UI/TonConnectButton";
import coin from 'assets/images/coin.png'

type Props = {};

const EarnTapping = (props: Props) => {
  const { user, products,locale } = useContext(appContext);

  const goods = products?.reduce(
    (g: { avalaible: Product[]; bougth: Product[] }, p) => {
      if (Number(p.boost_tap)) {
        if (p.in_inventory) g.bougth.push(p);
        else g.avalaible.push(p);
      }
      return g;
    },
    { avalaible: [], bougth: [] }
  );
  if(!locale) return null
  const text = locale.earnPage.tapping
  const mainText = locale.general

  return (
    <div className="earn-boosters">
      <ul className="earn-boosters-stats">
        <li>
        <img src={coin} />
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>{mainText.balance}</span>
          </div>
        </li>
        <li>
          <Icon icon="minCoin" />
          <div>
            <p>{formatNumber(user?.profit_hour)}</p>
            <span>{mainText.profitHour}</span>
          </div>
        </li>
        <li>
          <Icon icon="tapIcon" />
          <div>
            <p>{formatNumber(user?.coins_per_tap)}</p>
            <span>{mainText.tokenTap}</span>
          </div>
        </li>
      </ul>
    <TonConnectButton/>
      <div className="boosters-list-wrapper">
      {!!goods?.avalaible.length &&
        <>
        <div className="divider">
          <hr /> <h3>{text.sectionTitleTapBoosters}</h3> <hr />
        </div>
        <ul className="boosters-list tapping-goods-list">
          {goods?.avalaible.map((g) => (
              <EarnTappingBoosterCard product={g} key={g.id} />
            ))}
        </ul>
        </>
      }
      {!!goods?.bougth.length && (
        <>
          <div className="divider">
            <hr /> <h3>{text.sectionTitleUnlocked}</h3> <hr />
          </div>
          <ul className="boosters-list tapping-goods-list">
            {goods?.bougth.map((g) => (
              <EarnTappingBoosterCard product={g} key={g.id} />
            ))}
          </ul>
        </>
      )}
      </div>
    </div>
  );
};

export default EarnTapping;
