import { motion } from 'framer-motion';
import React, { useContext } from 'react'
import Button from 'UI/Button';
import Icon from 'UI/Icon';
import Product from 'types/product';
import appContext from 'utils/app-context';
import coin from 'assets/images/coin.png'

type Props = {
    pearl: Product;
    onClick: (p: Product) => void;
  }

const PearlCard = ({
    pearl,
    onClick,
  }:Props ) => {
    const { image, boost_profit_hour, title,in_inventory,price,price_friends,price_ton,id } = pearl;
    const {locale} = useContext(appContext)
    const text = locale?.pearlsPage
    const mainText = locale?.general
    return (
      <motion.li layout key={id} className="boosters-list-item tapping-goods-list-item" onClick={() => onClick(pearl)}>
        <motion.img layout transition={{duration:0.2}} src={image} alt="" layoutId={image} />
        <div className="boosters-list-item-desc tapping-good-desc">
          <h4>{title}</h4>
          <div className="per-tap">
             <img src={coin} className="coin-img"/>
            <p>
              +{boost_profit_hour}{mainText?.tkn_short}<span>/{mainText?.hr_short}</span>
            </p>
          </div>
        {!in_inventory && <><p className="tapping-good-cost-text" style={{opacity:Number(!in_inventory)}}>
          {text?.willCostOneOf}
        </p>
        <ul className="cost-variants" style={{opacity:Number(!in_inventory)}}>
          {!!price && (
            <li>
               <img src={coin} className="coin-img"/>
              <span>{price / 1000}k</span>
            </li>
          )}
          {!!price_friends && (
            <li>
              <Icon icon="person" />
              <span>{price_friends}</span>
            </li>
          )}
          {!!price_ton && (
            <li>
              <Icon icon="ton" />
              <span>{price_ton}</span>
            </li>
          )}
        </ul></>}
         {!!in_inventory && <Button
          layoutId={title}
          transition={{duration:0.2}}
            className={`boosters-list-item-btn`}
            sandBtn
            onClick={() => onClick(pearl)}
          >
            {text?.look}
          </Button>}
        </div>
      </motion.li>
    );
  };
export default PearlCard