import getTasks from 'api/get-tasks'
import React, { useContext, useEffect, useState } from 'react'
import Task from 'types/task'
import PageWrapper from 'UI/PageWrapper'
import UserBalance from 'UI/UserBalance'
import './Tasks.css'
import Button from 'UI/Button'
import Icon from 'UI/Icon'
import formatNumber from 'utils/format-number'
import TaskCard from './components/TaskCard'
import appContext from 'utils/app-context'
type Props = {}

const Tasks = (props: Props) => {
    const [tasks, setTasks] = useState<Task[] | null>(null)
    const {locale} = useContext(appContext)
    const updateTasks = async () => {
        const result = await getTasks()
        if(result) setTasks(result.tasks)
    }
    useEffect(() => {
        updateTasks()
    }, [])

    const unfinished = tasks?.filter(t => t.status !== 1)
    const finished = tasks?.filter(t => t.status === 1)
    if(!locale) return null
    const text = locale.tasksPage
  return (
    <PageWrapper className='earn-page tasks-page'>
        <UserBalance />
        <h1 dangerouslySetInnerHTML={{__html:text.title_html}}/>
        <div className="boosters-list-wrapper tasks-list-wrapper">
        {!!unfinished?.length && <div className="divider">
          <hr /> <h3>{text.sectionTitleUnfinished}</h3>  <hr />
        </div>}
        <ul className="boosters-list">
        {unfinished?.map((t) => {
            return <TaskCard key={t.id} updateTasks={updateTasks} t={t}/>
          })}
        </ul>
        {!!finished?.length && <div className="divider">
          <hr /> <h3>{text.sectionTitleFinished}</h3>  <hr />
        </div>}
        <ul className="boosters-list">
          {finished?.map((t) => {
            return <TaskCard key={t.id} updateTasks={updateTasks} t={t}/>
          })}
        </ul>
      </div>
    </PageWrapper>
  )
}

export default Tasks