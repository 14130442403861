import Lang from "types/lang";
import Locale from "types/locale";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;
export default async (code:Lang['code']):Promise<Locale | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "locales";
    API_URL.searchParams.set("code", String(code));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
