import React, { useContext } from "react";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import Icon from "./Icon";
import coin from 'assets/images/coin.png'

type Props = {
  showEnergy?: true;
};

const UserBalance = ({ showEnergy }: Props) => {
  const { user,locale } = useContext(appContext);
  return (
    <div className="user-balance-container">
      {showEnergy ? (
        <div className="user-balance energy">
          {/* <span className="sand-button energy-circle">
            <img src={energyImg} alt="" />
          </span> */}
          <Icon icon="energy"/>
          <p>
            {formatNumber(user?.energy)} <span>/ {formatNumber(user?.max_energy)}</span>
          </p>
        </div>
      ) : (
        <div className="user-profile">
          <img src={user?.avatar} alt="" />
          <p>{user?.first_name}</p>
        </div>
      )}
      <div className="user-balance">
        <p>
          {formatNumber(user?.balance)} <span>{locale?.general.tkn_short}</span>
        </p>
        <img src={coin} className="user-balance-coin"/>
      </div>
    </div>
  );
};

export default UserBalance;
