import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import React, { useContext, useEffect } from "react";
import Button from "UI/Button";
import Icon from "UI/Icon";
import { bgVariants, opacityVariants } from "utils/modal-animation";
import tg from "utils/tg";
import appContext from "utils/app-context";
import PearlBuyModal from "./PearlBuyModal";
import Product from "types/product";
import usePurchase from "hooks/use-purchase";

type Props = {
  OpenedPearl: Product | null;
  close: () => void;
};

const PearlOverview = ({ OpenedPearl, close }: Props) => {
  const {products,updateProducts,locale} = useContext(appContext);
  let getMoreProduct = products?.find(g => Number(g.get_link))
  const {handleBuyProduct,loading} = usePurchase(getMoreProduct)


  const handleGetMore = () => {
    const onSuccess =  () => {
     setTimeout( async() => {
      const products = await updateProducts()
      if(products) {
        getMoreProduct = products?.find(g => Number(g.get_link))
        openLink(getMoreProduct?.link)
      }
     }, 500);
    } 
    handleBuyProduct(onSuccess)
  }

  const openLink = (link?:string) => {
    if(!link) return
    try {
      tg.openTelegramLink(link)
    } catch (error) {
      tg.openLink(link)
    }
  }

  useEffect(() => {
    tg.BackButton.onClick(close);
    return () => {
      tg.BackButton.offClick(close);
    };
  }, []);
  if(!locale) return null
  const text = locale.pearlsPage
  return (
    <AnimatePresence>
      {OpenedPearl && (
        <motion.div
          className="modal-bg pearl-images"
          {...bgVariants}
          transition={{ duration: 0.1 }}
        >
          <motion.div {...opacityVariants} className="pearl-images-container">
            <ul className="pearl-images-list">
              <motion.li className="pearl-image">
                <motion.img
                  layout
                  src={OpenedPearl.image}
                  layoutId={OpenedPearl.image}
                  alt=""
                />
              </motion.li>
              {
                OpenedPearl.images.map(({image}) => (
                  <motion.li key={image} {...LiVariants}  className="pearl-image">
                  <img src={image} alt="" />
                </motion.li>
                ))
              }
            </ul>
          </motion.div>
          <motion.div {...opacityVariants} className="pearl-images-buttons">
            <Button sandBtn className="back" onClick={close}>
              <Icon icon="arrowLeft" />
            </Button>
            {
              getMoreProduct?.link
                ? <Button sandBtn className="more tg-currency active" layoutId={OpenedPearl.title} onClick={() => openLink(getMoreProduct?.link)}>
                    {text.openTheChanel}
                  </Button>
                : <Button sandBtn loading={loading} className="more" layoutId={OpenedPearl.title} onClick={handleGetMore}>
                    {text.getMore} <span><Icon icon="ton"/> {getMoreProduct?.price_ton}</span>
                  </Button>
            }
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
const LiVariants: HTMLMotionProps<"li"> = {
  initial: { y: 100, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 100, opacity: 0 },
  transition: { delay: 0.3 },
};
export default PearlOverview;
