import { useContext, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import UserBalance from "UI/UserBalance";
import "./Pearls.css";
import PearlOverview from "./components/PearlOverview";
import PearlCard from "./components/PearlCard";
import appContext from "utils/app-context";
import Product from "types/product";
import PearlBuyModal from "./components/PearlBuyModal";
import TonConnectButton from "UI/TonConnectButton";

const Pearls = () => {
  const [OpenedPearl, setOpenedPearl] = useState<Product | null>(
    null
  );
  const {products,setModal,locale} = useContext(appContext)

  const goods = products?.reduce((g:{avalaible:Product[],bougth:Product[]},p) => {
    if (p.boost_profit_hour) {
      if (p.in_inventory) g.bougth.push(p)
      else g.avalaible.push(p)
    }
    return g
  },{avalaible:[],bougth:[]})
  const handlePearlClick = (p:Product) => {
    if (p.in_inventory) setOpenedPearl(p)
    else setModal({content:<PearlBuyModal good={p}/>,close:true })
  }
  if(!locale) return null
  const text = locale.pearlsPage
  return (
    <>
      <PageWrapper className="pearls-page">
        <UserBalance showEnergy />
        <TonConnectButton/>
       {!!goods?.avalaible.length && <div className="boosters-list-wrapper pearls-list">
        <div className="divider">
            <hr /> <h3>{text.sectionTitle}</h3> <hr />
          </div>
        <ul className="boosters-list tapping-goods-list ">
          {goods?.avalaible.map((p) => {
            return <PearlCard pearl={p} onClick={handlePearlClick} key={p.id}/>;
          })}
        </ul>
        </div>}
       {!!goods?.bougth.length && <div className="boosters-list-wrapper">
          <div className="divider">
            <hr /> <h3>{text.sectionTitleUnlocked}</h3> <hr />
          </div>
          <ul className="boosters-list tapping-goods-list">
          {goods?.bougth.map((p) => {
            return <PearlCard pearl={p} onClick={handlePearlClick} key={p.id}/>;
          })}
        </ul>
        </div>}
        
      </PageWrapper>
      <PearlOverview OpenedPearl={OpenedPearl} close={() => setOpenedPearl(null)}/>
    </>
  );
};



export default Pearls;
