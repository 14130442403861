import { SectionTitles } from "types/locale";
import EarnBoosters from "./sections/EarnBoosters";
import EarnDaily from "./sections/EarnDaily";
import EarnTapping from "./sections/EarnTapping";
import EarnRoulette from "./sections/EarnRoulette";

export type EarnSectionType = {
  section: JSX.Element;
  title: keyof SectionTitles;
};

const EarnSections: EarnSectionType[] = [
  {
    title: "roulette",
    section: <EarnRoulette />,
  },
  {
    title: "daily",
    section: <EarnDaily />,
  },
  {
    title: "boosters",
    section: <EarnBoosters />,
  },
  {
    title: "tapping",
    section: <EarnTapping />,
  },
];
export default EarnSections;
