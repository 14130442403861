import { HTMLMotionProps, motion } from 'framer-motion'
import React, { useState } from 'react'
import tg from 'utils/tg'
import Icon from './Icon'

interface Props extends HTMLMotionProps<"button"> {
  sandBtn?:boolean
  errorIf?:boolean
  loading?:boolean
}

const Button = (props: Props) => {
  const [isShaking, setIsShaking] = useState(false)

  return (
    <motion.button
    onPointerDown={(e) => {
        tg.HapticFeedback.selectionChanged()
        if(props.errorIf || props.disabled) setIsShaking(true)
        if(props.onPointerDown) props.onPointerDown(e)
        setTimeout(() => setIsShaking(false), 500)
    }
    }
    onClick={(e) => {
      if(props.disabled) tg.HapticFeedback.notificationOccurred('error')
      if(props.onClick) props.onClick(e)
    }}
    animate={isShaking ? { x: [-7, 7, -7, 7, -7, 0], transition:{duration:0.4} } : {x:0}}
    whileTap={isShaking ? {} : {scale:0.95}}
    {...props}
    className={`${props.sandBtn ? 'sand-button' : ''} ${props.disabled ? 'disabled-button' : ''} ${props.className ?? ''}`}
    >
        {props.children as React.ReactNode}
        {props.loading && <span className='btn-loading-icon'><Icon icon='loading'/></span>}
    </motion.button>
  )
}

export default Button