import React, { useContext } from "react";
import Button from "UI/Button";
import appContext from "utils/app-context";

type Props = {
  disconnect: () => void;
};

const DisconnectWalletConfirm = ({ disconnect }: Props) => {
  const { setModal ,locale} = useContext(appContext);
  if(!locale) return null
  const text = locale?.tonConnect

  return (
    <>
      <h2 dangerouslySetInnerHTML={{__html:text?.disconnectModalTitle_html}} style={{ textAlign: "center", fontWeight: 500 }}/>
      <p style={{ textAlign: "center", margin: "5px 0 20px" }}>
       {text.disconnectModalDesc}
      </p>
      <div style={{ width: "100%", display: "flex", gap: 10 }}>
        <Button
          sandBtn
          style={{ borderRadius: 12 }}
          className="daily-reward-gain"
          onClick={() => setModal(null)}
        >
          {text.n}
        </Button>
        <Button
          className="daily-reward-gain disconnect-btn"
          onClick={() => {
            disconnect();
            setModal(null);
          }}
        >
          {text.y}
        </Button>
      </div>
    </>
  );
};

export default DisconnectWalletConfirm;
