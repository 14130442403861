import React, { useContext, useEffect, useRef, useState } from "react";
import EarnSection from "../components/EarnSection";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import energyImg from "assets/images/energy-booster-image.jpg";
import TimerText from "UI/TimerText";
import Button from "UI/Button";
import EnergyBoosterCard from "../components/EnergyBoosterCard";
import getFullEnergyStatus from "api/get-full-energy-status";
import FreeEnergyStatus from "types/free-energy-status";
import claimFullEnergy from "api/claim-full-energy";
import tg from "utils/tg";
import coin from 'assets/images/coin.png'

type Props = {};

const EarnBoosters = (props: Props) => {
  const { user ,setModal,products,setUser,locale} = useContext(appContext);
  const [freeEnergyStatus, setFreeEnergyStatus] = useState<FreeEnergyStatus | null>(null)
  const energyBoosters = products?.filter(({boost_energy}) => boost_energy)
  const [loading, setloading] = useState(true)

  const updateFreeEnergyStatus = async () => {
    const result = await getFullEnergyStatus()
    if(result) {
      setFreeEnergyStatus(result)
    }
    setloading(false)
    return result
  }

  const handleClaimEnergy = async () => {
    if(!freeEnergyStatus?.active) {
      tg.HapticFeedback.notificationOccurred('error')
      return
    }
    setloading(true)
    const result = await claimFullEnergy()
    if(result && result.success) setUser(prev => {
      if(!prev) return prev
      return ({...prev,energy:result.energy})
    })
    const updateResult = await updateFreeEnergyStatus()
    if(updateResult) setModal({content:<GetEnergyModal available={updateResult.available} from={updateResult.from}/>})
    setloading(false)
  }
  

  useEffect(() => {
    updateFreeEnergyStatus()
  }, [])
  if(!locale) return null
  const text = locale.earnPage.boosters
  const mainText = locale.general
  return (
    <div className="earn-boosters">
      <ul className="earn-boosters-stats">
        <li>
          <img src={coin}/>
          <div>
            <p>{formatNumber(user?.balance)}</p>
            <span>{mainText.balance}</span>
          </div>
        </li>
        <li>
          <Icon icon="energy" />
          <div>
            <p>{formatNumber(user?.energy)}</p>
            <span>{mainText.energyBank}</span>
          </div>
        </li>
        <li>
          <Icon icon="tapIcon" />
          <div>
            <p>{formatNumber(user?.coins_per_tap)}</p>
            <span>{mainText.tokenTap}</span>
          </div>
        </li>
      </ul>
      <h1 dangerouslySetInnerHTML={{__html:text.decs_html}}/>

      <div className="boosters-list-wrapper">
        <div className="divider">
          <hr /> <h3>{text.sectionTitleFreeB}</h3>  <hr />
        </div>
        <ul className="boosters-list">
          <li className="boosters-list-item">
            <img src={energyImg} alt="" style={{filter:freeEnergyStatus?.active ? '' :'grayscale(1)'}}/>
            <div className="boosters-list-item-desc">
              <h4 dangerouslySetInnerHTML={{__html:text.freeBoosterTitle_html}}/>
              <Button
                errorIf={!freeEnergyStatus?.active}
                loading={loading}
                onClick={handleClaimEnergy}
                className="boosters-list-item-btn" 
                sandBtn={!!freeEnergyStatus?.active}>
                {freeEnergyStatus?.active
                ? <p>{mainText.getThat} ({freeEnergyStatus.available}/{freeEnergyStatus.from})</p>
                : <p>{mainText.availableVia} {freeEnergyStatus && <TimerText time={freeEnergyStatus.seconds} onEnd={updateFreeEnergyStatus}/>}</p>
                }
              </Button>
            </div>
          </li>
        </ul>
      </div>

      <div className="boosters-list-wrapper">
        <div className="divider">
          <hr /> <h3>{text.sectionTitleB}</h3>  <hr />
        </div>
        <ul className="boosters-list">
          {energyBoosters?.map((p) => <EnergyBoosterCard p={p} key={p.id}/>)}
        </ul>
      </div>

    </div>
  );
};

type GetEnergyModalProps = {
  available:number,from:number
}

const GetEnergyModal = ({available,from}:GetEnergyModalProps) => {
  const { setModal,locale } = useContext(appContext);
  if(!locale) return null
  const text = locale.earnPage.boosters
  const mainText = locale.general
  return (
    <>
      <h2 dangerouslySetInnerHTML={{__html:text.boosterModalTitle_html}} style={{textAlign:'center',fontWeight:500}}/>
      <p style={{textAlign:'center',margin:'5px 0 20px'}}>
        <span className="sand-text">{available}/{from} {text.charges}</span> {text.areLeft}
      </p>
      <Button
          sandBtn
          className="daily-reward-gain"
          onClick={() => setModal(null)}
        >
          {mainText.continue}
        </Button>
    </>
  );
}


export default EarnBoosters;
