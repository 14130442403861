import tg from "utils/tg";
import UserData from "types/user-data";
const tg_id = tg.initDataUnsafe.user?.id;

interface Responce {
  result: boolean;
  data?: {
    balance:number
    energy:number
    level: number;
    alert: string | null;
  };
  errorMessage?: string
}
export default async (taps:number,energy:number):Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "taps";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("taps", String(taps));
    API_URL.searchParams.set("energy", String(energy));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
