import { HTMLMotionProps } from "framer-motion";

export const bodyVariants: HTMLMotionProps<"div"> = {
  initial: {
    opacity: 0,
    y: -100,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 100,
  },
};

export const opacityVariants: HTMLMotionProps<"div"> = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };
  

export const bgVariants: HTMLMotionProps<"div"> = {
  initial: {
    background: "transparent",
    backdropFilter: "blur(0px)",
  },
  animate: {
    background: "#000000b3",
    backdropFilter: "blur(10px)",
  },
  exit: {
    background: "transparent",
    backdropFilter: "blur(0px)",
  },
  transition: {
    // duration:0.4
  },
};
