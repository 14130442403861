import usePurchase from 'hooks/use-purchase'
import { useContext } from 'react'
import Product from 'types/product'
import Button from 'UI/Button'
import Icon from 'UI/Icon'
import appContext from 'utils/app-context'

type Props = {
    p:Product
}

const EnergyBoosterCard = ({p}: Props) => {
    const {title,price_ton} = p
    const {loading,handleBuyProduct} = usePurchase(p)
    const {setModal,locale} = useContext(appContext)
  const mainText = locale?.general
  return (
    <li className="boosters-list-item">
            <div className="energy-booster-img">
              <Icon icon="bigEnergyIcon"/>
            </div>
            <div className="boosters-list-item-desc">
              <h4>{title}</h4>
              <div className="boosters-list-item-btn-container">
              <Button loading={loading} className="boosters-list-item-btn" sandBtn onClick={() => {
                const onSuccess = () => setModal({content:<GetEnergyModal/>})
                handleBuyProduct(onSuccess)
              }}>
                <p>{mainText?.getThat}</p>
              </Button>
              <div className="boosters-list-item-cost">
                <Icon icon="ton"/>
                <span>{price_ton}</span>
              </div>
              </div>
            </div>
          </li>
  )
}

const GetEnergyModal = () => {
    const { setModal,locale } = useContext(appContext);
    if(!locale) return null
    const text = locale.earnPage.boosters
    const mainText = locale.general
    return (
      <>
        <h2 dangerouslySetInnerHTML={{__html:text.boosterModalTitle_html}} style={{textAlign:'center',fontWeight:500}}/>
        <Button
            sandBtn
            className="daily-reward-gain"
            onClick={() => setModal(null)}
          >
            {mainText.continue}
          </Button>
      </>
    );
  }

export default EnergyBoosterCard