import React, { useContext } from 'react'
import Prize from 'types/prize'
import Button from 'UI/Button'
import appContext from 'utils/app-context'

type Props = {
    prizes:Prize[]
}

const EarmRouletteMyPrizes = ({prizes}: Props) => {
    const {setModal,locale} = useContext(appContext)
    if (!locale) return null;
  const text = locale.earnPage.roulette;
  const mainText = locale.general;
  return (
    <div className='my-prizes-modal'>
    <h2 style={{textAlign:'center',fontWeight:500}}>
    {text.myPrizes}
    </h2>
    <div className="my-prizes-container">
        <ul className='my-prizes-list'>{prizes.map(({image,in_inventory,title,id}) => (
            <li>
                <img src={`img/prizes/${image}`} alt="" />
                <h4>{title}</h4>
                <div className={in_inventory ? 'sand-text' : ''}>{in_inventory} / pc.</div>
            </li>
        ))}</ul>
    </div>
    <Button
        sandBtn
        className="daily-reward-gain"
        onClick={() => setModal(null)}
      >
       {mainText.Close}
      </Button>
  </div>
  )
}

export default EarmRouletteMyPrizes