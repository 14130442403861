import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext } from 'react'
import Prize from 'types/prize'
import Button from 'UI/Button'
import appContext from 'utils/app-context'
import { bgVariants } from 'utils/modal-animation'

type Props = {
    prize:Prize
}

const EarnRouletteWinPrize = ({prize}: Props) => {
    const {setModal,locale} = useContext(appContext)
    if (!locale) return null;
    const text = locale.earnPage.roulette;
    const mainText = locale.general;
  return (
    <div className='prize-win-container'>
        <h2 style={{textAlign:'center',fontWeight:500}}>{text.modalTitle}</h2>
        <motion.img src={`img/prizes/${prize.image}`} layout layoutId={String(prize.id)} alt="" />
        <h2 style={{textAlign:'center',margin:'5px 0 20px'}}>
            <span className="sand-text">{prize.title}</span>
        </h2>
        <Button sandBtn onClick={() => setModal(null)}>{mainText.letsRock}</Button>
    </div>
  )
}

export default EarnRouletteWinPrize