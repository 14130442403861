import usePurchase from "hooks/use-purchase";
import { useContext } from "react";
import Product from "types/product";
import ProductCurrency from "types/product-currency";
import Button from "UI/Button";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import tg from "utils/tg";
import coin from 'assets/images/coin.png'

type PearlBuyModalProps = { good: Product };
const PearlBuyModal = ({
  good,
}: PearlBuyModalProps) => {
  const { title, price_ton, price, price_friends ,id,boost_profit_hour} = good
  const { user ,setModal,updateProducts,updateUserInfo,locale} = useContext(appContext);
  const {handleBuyProduct,loading,success,currency,setcurrency} = usePurchase(good)
  
  if (!user || !locale) return null;
  const text = locale.pearlsPage
  const mainText = locale.general

  const avalaibleForFriends = user?.friends >= price_friends;
  const avalaibleForCoins = user?.balance >= price;
  return (
    <>
      <h2 style={{ textAlign: "center", fontWeight: 500 }}>
        {text.pearlModalTitle} <br />
        <span className="sand-text2">«{title}»</span>
      </h2>
      <p style={{ textAlign: "center", margin: "5px 0 20px" }}>
      {text.ItWillBring} <span className="sand-text2">{boost_profit_hour} {mainText.tkn_many}</span> {text.everyHour} <br />
       {text.pearlModalDesc}
      </p>
      <ul className="buy-boost-buttons">
        {!!price_ton && (
          <li>
            <Button
              className={`buy-boost-select-currency tg-currency ${
                currency === "price_ton" && "active"
              }`}
              onClick={() => setcurrency("price_ton")}
              style={{}}
            >
              <Icon icon="ton" /> {price_ton} {mainText.currencyTon}
            </Button>
          </li>
        )}
        {!!price_friends && (
          <li>
            <Button
              className={`buy-boost-select-currency fr-currency ${
                currency === "price_friends" && "active"
              }`}
              errorIf={!avalaibleForFriends}
              onClick={() => {
                if (avalaibleForFriends) setcurrency("price_friends");
                else tg.HapticFeedback.notificationOccurred("error");
              }}
            >
              <Icon icon="person" />{" "}
              {avalaibleForFriends ? "" : `${user.friends} /`} {price_friends}{" "}
              {mainText.currencyFriends}
            </Button>
          </li>
        )}
        {!!price && (
          <li>
            <Button
              className={`buy-boost-select-currency cn-currency ${
                currency === "price" && "active"
              }`}
              errorIf={!avalaibleForCoins}
              onClick={() => {
                if (avalaibleForCoins) setcurrency("price");
                else tg.HapticFeedback.notificationOccurred("error");
              }}
            >
               <img src={coin} className="coin-img"/>{" "}
              {avalaibleForCoins ? "" : `${user.balance} /`}{" "}
              {formatNumber(price)} {mainText.tkn_many}
            </Button>
          </li>
        )}
      </ul>
      <Button disabled={currency === null} sandBtn loading={loading} className="buy-boost-btn" 
        onClick={() => handleBuyProduct(() => {
          setTimeout(() => {
            setModal(null)
            updateUserInfo()
            setTimeout(updateProducts,400)
          }, 500);
        })}
        >
        {success ? mainText.success : mainText.continue}
      </Button>
      <div className="not-available-info">
        {!avalaibleForFriends && <p>{mainText.enoughtFriends}</p>}
        {!avalaibleForCoins && <p>{mainText.enoughtTokens}</p>}
      </div>
    </>
  );
};
  
  export default PearlBuyModal